<template>
  <div id="echartsMap" ref="echartsMap"></div>
</template>
<script>
import echarts from 'echarts';
import 'echarts/map/js/china.js'; // 引入中国地图数据

export default {
  name: 'echarts_map',
  props: {
    echartsData: Array,
  },
  data() {
    return {
      // 图表配置信息
      options: {},
      dataList: [],
    };
  },
  watch: {
    echartsData: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.dataList = val.map((v) => ({ name: v.name, value: v.actualSales }));
        }
      },
    },
  },
  methods: {
    getOptions() {
      const options = {
        tooltip: {
          triggerOn: 'mousemove', // mousemove、click
          formatter(val) {
            const str = `${val.name}<br />${val.seriesName}：${val.value ? val.value : '-'}`;
            return str;
          },
        },
        visualMap: {
          left: 'right',
          min: 0,
          max: 40000000,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026'],
          },
        },
        geo: {
          map: 'china',
          scaleLimit: {
            min: 1,
            max: 2,
          },
          zoom: 1,
          top: 20,
          left: 20,
          right: 20,
          bottom: 20,
          itemStyle: {
            areaColor: '#eeeeee',
          },
          emphasis: { // 高亮状态下的多边形和标签样式。
            itemStyle: {
              areaColor: '#f2d5ad',
            },
          },
        },
        series: [
          {
            name: '销售额',
            type: 'map',
            geoIndex: 0,
            data: this.dataList,
          },
        ],
      };
      return options;
    },
    // 初始化中国地图
    initEchartMap() {
      const chartDiv = this.$refs.echartsMap;
      const myChart = echarts.init(chartDiv);
      myChart.setOption(this.getOptions());
    },
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.initEchartMap();
    });
  },
};
</script>
<style lang="less" scoped>
#echartsMap{
  width: 100%;
  height: 100%;
}
</style>
